import { getCookie, setCookie } from "cookies-next";
import PropTypes from "prop-types";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  FAVOURITE_ITEMS_COOKIE_MAX_AGE,
  FAVOURITE_ITEMS_STORAGE_KEY,
} from "~config/favouriteItems";
import * as analyticsEvents from "~lib/analytics/events";
import * as preprEvents from "~lib/prepr/analytics/events";

/**
 * @typedef {object} FavouriteItem
 * @property {string} id
 * @property {string} [title]
 */

const updateFavouritesCookie = (favouriteIds) => {
  setCookie(
    FAVOURITE_ITEMS_STORAGE_KEY,
    JSON.stringify([...new Set(favouriteIds)]),
    {
      maxAge: FAVOURITE_ITEMS_COOKIE_MAX_AGE || undefined,
    },
  );
};

export const FavouriteItemsContext = createContext({});

export const FavouriteItemsProvider = ({ children }) => {
  const [favouriteItemIds, setFavouriteItemIds] = useState([]);
  const updateListeners = useRef([]);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const cookieFavouriteItems = getCookie(FAVOURITE_ITEMS_STORAGE_KEY);
    if (cookieFavouriteItems) {
      const favourites = JSON.parse(cookieFavouriteItems);
      setFavouriteItemIds(Array.isArray(favourites) ? favourites : []);
    }
  }, []);

  const addFavouriteItems = useCallback(
    /**
     * @param {FavouriteItem[]} items
     */
    (items) => {
      const favourites = favouriteItemIds || [];
      const updatedFavourites = [...favourites];

      items.forEach(({ id, title }) => {
        if (favourites.includes(id)) {
          return;
        }
        updatedFavourites.push(id);

        preprEvents.triggerBookmarkEvent(id);
        if (title) {
          analyticsEvents.pushFavouriteEvent(title);
        }
      });

      updateListeners.current.forEach((listener) =>
        listener(updatedFavourites, favourites),
      );

      updateFavouritesCookie(updatedFavourites);
      setFavouriteItemIds(updatedFavourites);
    },
    [favouriteItemIds],
  );

  const addFavouriteItem = useCallback(
    /**
     *
     * @param {FavouriteItem} item
     */
    ({ id, title }) => {
      const favourites = favouriteItemIds || [];
      if (!favourites.includes(id)) {
        const updatedFavourites = [...favourites, id];
        updateFavouritesCookie(updatedFavourites);
        setFavouriteItemIds(updatedFavourites);
        updateListeners.current.forEach((listener) =>
          listener(updatedFavourites, favourites),
        );

        // trigger analytics events
        preprEvents.triggerBookmarkEvent(id);
        if (title) {
          analyticsEvents.pushFavouriteEvent(title);
        }
      }
    },
    [favouriteItemIds],
  );

  const removeFavouriteItem = useCallback(
    /**
     * @param {FavouriteItem} item
     */
    ({ id, title }) => {
      if (!favouriteItemIds?.includes(id)) {
        return;
      }
      const updatedFavourites = favouriteItemIds.filter((id) => id !== id);
      updateFavouritesCookie(updatedFavourites);
      setFavouriteItemIds(updatedFavourites);
      updateListeners.current.forEach((listener) =>
        listener(updatedFavourites, favouriteItemIds),
      );

      // trigger analytics events
      preprEvents.triggerUnbookmarkEvent(id);
      if (title) {
        analyticsEvents.pushUnfavouriteEvent(title);
      }
    },
    [favouriteItemIds],
  );

  const toggleFavouriteItem = useCallback(
    /**
     * @param {FavouriteItem} item
     */
    (item) => {
      if (favouriteItemIds?.includes(item.id)) {
        removeFavouriteItem(item);
      } else {
        addFavouriteItem(item);
      }
    },
    [favouriteItemIds, addFavouriteItem, removeFavouriteItem],
  );

  const addOnChangeListener = useCallback((listener) => {
    updateListeners.current.push(listener);
    return () => {
      updateListeners.current = updateListeners.current.filter(
        (l) => l !== listener,
      );
    };
  }, []);

  const value = useMemo(
    () => ({
      favouriteItemIds: favouriteItemIds || [],
      toggleFavouriteItem,
      addFavouriteItem,
      addFavouriteItems,
      removeFavouriteItem,
      onFavouritesChange: addOnChangeListener,
    }),
    [
      favouriteItemIds,
      addFavouriteItem,
      addFavouriteItems,
      removeFavouriteItem,
      toggleFavouriteItem,
      addOnChangeListener,
    ],
  );

  return (
    <FavouriteItemsContext.Provider value={value}>
      {children}
    </FavouriteItemsContext.Provider>
  );
};

FavouriteItemsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
