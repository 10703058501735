const variables = {
  baseUrl: {
    local: "http://localhost:3000",
    development: "https://iamsterdam-website-development.vercel.app",
    test: "https://iamsterdam-website-development.vercel.app",
    acceptance: "https://acceptance.iamsterdam.com",
    production: "https://www.iamsterdam.com",
  },
  cardApiBaseUrl: {
    development: "https://testscanner.cardapi.io/cks",
    test: "https://testscanner.cardapi.io/cks",
    acceptance: "https://scanner.cardapi.io/cks",
    production: "https://scanner.cardapi.io/cks",
  },
  gtmId: {
    local: null,
    test: null,
    development: "GTM-WNMKQQ",
    acceptance: "GTM-WNMKQQ",
    production: "GTM-WNMKQQ",
  },
  recaptchaEnabled: {
    development: false,
    test: true,
    acceptance: true,
    production: true,
  },
  importerBaseUrl: {
    development:
      "https://europe-west1-i-amsterdam-361113.cloudfunctions.net/feed-factory-sync-dev",
    test: "https://europe-west1-i-amsterdam-361113.cloudfunctions.net/feed-factory-sync-dev",
    acceptance:
      "https://europe-west1-i-amsterdam-361113.cloudfunctions.net/feed-factory-sync",
    production:
      "https://europe-west1-i-amsterdam-361113.cloudfunctions.net/feed-factory-sync",
  },
  aws: {
    s3BucketName: {
      development: "iamsterdam-dev",
      test: "iamsterdam-dev",
      acceptance: "iamsterdam",
      production: "iamsterdam",
    },
  },
};

module.exports = variables;
